import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LandingPage from "./pages/Landingpage";
import BasicLanding from "./pages/BasicLanding";
import Login from './pages/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<BasicLanding />}/>
        <Route path='/otherlanding' element={<LandingPage/>}/>
        <Route path='/login' element={<Login/>}/>
      </Routes>
    </Router>
  );
}

export default App;
