import { createTheme } from "@mui/material";
import React from "react";

export const style = createTheme({
    palette:{
        primary:{
            main: '#ffffff',
        },
        secondary:{
            main: '#f71000',
        },
        testColor:{
            main: '#999',
        },
        nav_bar_color:{
            main: '#ffffff',
        }
    },
    nav_bar:{
        main: "#f71000",
        color: "#f71000"
    },
    link: {
        marginRight: 100,
        color: "black",
    },
})