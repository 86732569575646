
import React, {  useState } from 'react';
import {AppBar, Toolbar, Container, Avatar, IconButton, Hidden, Paper,} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material'
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { style } from '../themes/themes'

const useStyles = makeStyles({
  avatar: {
    marginRight: "auto",
  },
  nav_bar: {
    background: "#ffffff"
  },
});

const navlinks = [
  {name: "Über uns", href: ""},
  {name: "Kommt noch", href: ""}
]

function MenuComp(){
  
  const styles = useStyles();

    return(
    <>
          <AppBar position="sticky" color='nav_bar_color'>
            <Container maxWidth="md">
              <Toolbar disableGutters>
                <Avatar className={styles.avatar}>K2</Avatar>
                <Paper variant='h2' elevation={0} sx={{visibility: {xs: 'hidden', sm: 'visible'}, backgroundColor: style.palette.nav_bar_color, marginRight: '10px'}}>
                  {navlinks.map((item) => (
                    <Link 
                      classes={style.link}
                      href={item.href}
                      underline="none"
                      color={style.link.color}
                    >
                      <>{item.name}</> 
                    </Link>
                  ))}
                </Paper>
                <Paper sx={{display: {xs: "flex", sm: 'none'}}}>
                  <IconButton>
                    <MenuIcon/>
                  </IconButton>
                </Paper>
              </Toolbar>
            </Container>
          </AppBar>
        
</>
    );

}

export default (
    MenuComp
) 