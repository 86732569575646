import '../App.css';
import React from 'react';
import logo from '../logo.svg';
import {Grid, Container} from '@mui/material';
import {grey} from '@mui/material/colors';

function BasicLanding() {
  return (
    <>
    
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Das ist Kryp2tip.de - Wir bauen diese Website gerade noch! Aber sei gespannt was kommt.
        </p>

      </header>
    </div>
    
    <Container
          maxWidth="md"
          component="footer"
        >
        <Grid container color={grey[50]} spacing={4} justifyContent="space-evenly">

        <a
          className='Kryp2tip-link'
          href="https://kryp2tip.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kryp2tip.de
        </a>

        </Grid>
    </Container>
    </>
  );
}

export default BasicLanding;
